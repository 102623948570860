/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una de las alternativas al P2P, es el f2f, el intercambio face-to-face, es decir cara a cara, mas sencillo que nada y el mas antiguo del mundo, pero eso si con las nuevas tecnologías que nos facilitan la vida."), "\n", React.createElement(_components.p, null, "Ahora que tenemos acceso a pendrives y discos duros portatiles de alta capacidad, compartir por f2f es lo mas facil del mundo, solo hay que tener un sitio donde juntarse un pc, hoy en dia casi todo el mundo ya tiene un portatil o puede conseguir uno, y ya está."), "\n", React.createElement(_components.p, null, "¿Pero como podemos hacer mas fácil aún esta forma de compartir? Creando bibliotecas públicas digitales, lugares de intercambio, con servidores donde almacenar Gigas o incluso, Teras de cultura."), "\n", React.createElement(_components.p, null, "Un ejemplo de esto lo podemos encontrar en el ", React.createElement(_components.a, {
    href: "http://www.patiomaravillas.net/hamlab/fiesta-compartir-es-bueno-rellenemos-la-bodega-de-gigas"
  }, "Hamlab del Patio maravillas"), ", realmente un ejemplo a seguir."), "\n", React.createElement(_components.p, null, "Un saludo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
